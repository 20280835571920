import {RouterPrefix} from '@wix/wixstores-client-core/dist/es/src/constants';
import {LocaleKeys} from '../../../__generated__/LocaleKeys';
import {getCategoryPageStatusForSite} from './categoriesRolloutManagerUtils';
import {EditorType} from '@wix/editor-platform-sdk-types';
import {FedopsInteractions} from '../../constants';

export async function getCategoryPageSiteStatus(options) {
  const isCategorySiteRes = await getCategoryPageStatusForSite(
    options.initialAppData.metaSiteId,
    options.essentials.httpClient
  );
  return isCategorySiteRes.data.result.value;
}
export function isValidEditor(
  editorType: EditorType,
  categoryPageExperimentEnabledInEditorX: boolean,
  isWixStudio: boolean
) {
  const isClassicEditor = editorType === EditorType.Classic;
  if (isClassicEditor || isWixStudio) {
    return true;
  }

  return categoryPageExperimentEnabledInEditorX && editorType === EditorType.Responsive;
}
export function shouldInstallCategoryPage(
  editorType: EditorType,
  currentSiteCategoryPageState: boolean | null,
  categoryPageExperimentEnabledInEditorX: boolean,
  isWixStudio: boolean
): boolean {
  const validEditor = isValidEditor(editorType, categoryPageExperimentEnabledInEditorX, isWixStudio);
  const siteHadCategoryPageBefore = currentSiteCategoryPageState === true;
  const shouldInstallCategoryPageForTheFirstTime = currentSiteCategoryPageState === null;
  if (!validEditor) {
    return false;
  }
  return siteHadCategoryPageBefore || shouldInstallCategoryPageForTheFirstTime;
}

export async function createCategoryPageRouter(ecomPublicApi, categoryPageRef, fedopsLogger) {
  fedopsLogger?.interactionStarted?.(FedopsInteractions.CategoryAddRouter);
  const routerRef = await ecomPublicApi.addRouter(RouterPrefix.CATEGORY);
  fedopsLogger?.interactionEnded?.(FedopsInteractions.CategoryAddRouter);
  fedopsLogger?.interactionStarted?.(FedopsInteractions.CategoryConnectRouter);
  const router = await ecomPublicApi.getRouter(routerRef);
  await ecomPublicApi.connectPageToRouter({
    pageRef: categoryPageRef,
    routerRef,
    pageRoles: ['category'],
  });
  fedopsLogger?.interactionEnded?.(FedopsInteractions.CategoryConnectRouter);

  return router;
}

export function addAllProductsCategoryToDefaultSiteMenu(editorSdk, t, router, categoryPageRef) {
  return editorSdk.menu.addItem('', {
    menuId: 'CUSTOM_MAIN_MENU',
    menuItem: {
      type: 'BasicMenuItem',
      isVisible: true,
      label: t(LocaleKeys().siteMenu.store.defaultPage.name),
      link: {
        type: 'DynamicPageLink',
        routerId: router.id,
        pageId: categoryPageRef.id,
        innerRoute: 'all-products',
      },
    },
  });
}
