import {IHttpClient} from '@wix/fe-essentials-editor/src/exports/http-client/index';
import {Logger as WebBiLogger} from '@wix/web-bi-logger/dist/src/types';
import {exposureEventForTests} from '@wix/bi-logger-ec-sf/v2';

export function markSiteWithCategoryPageStatus(
  httpClient: IHttpClient,
  appInstance: string,
  value: boolean,
  webBiLogger?: WebBiLogger
) {
  if (!appInstance) {
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    webBiLogger?.report(
      exposureEventForTests({
        testName: 'missingAppInstance',
        type: appInstance,
        is_eligible: true,
      })
    );
  }

  return httpClient
    .post(
      '/_serverless/wixstores-categories-rollout-manager/markSiteAs',
      {
        value,
      },
      {
        headers: {Authorization: appInstance},
      }
    )
    .catch((error) => {
      // eslint-disable-next-line @typescript-eslint/no-floating-promises
      webBiLogger?.report(
        exposureEventForTests({
          testName: 'markSiteAs-FAILED',
          type: error,
          is_eligible: true,
        })
      );
    });
}

export function getCategoryPageStatusForSite(msid: string, httpClient: IHttpClient) {
  return httpClient.get<{result: {value: boolean}}>(
    `/_serverless/wixstores-categories-rollout-manager/siteRolloutStatus?metaSiteId=${msid}`
  );
}
