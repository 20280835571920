import {PageMap} from '@wix/wixstores-client-core/dist/es/src/constants';
import {EditorSDK, PageRef, ContextParams} from '@wix/platform-editor-sdk';
import {EcomPublicApi} from '../editorScript';
import {Logger as WebBiLogger} from '@wix/web-bi-logger/dist/src/types';
import {exposureEventForTests} from '@wix/bi-logger-ec-sf/v2';
import {getEcomPages} from '../../common/pages';
import {WIX_NEW_STORES} from '@wix/app-definition-ids';

export async function missingPagesMigration(
  sdk: EditorSDK,
  EPApi: EcomPublicApi,
  options: ContextParams,
  webBiLogger: WebBiLogger
) {
  const storesPages = await getEcomPages(sdk);
  const existsMandatoryPages = await Promise.all(
    storesPages.map((page) => {
      const pageRef: PageRef = {id: page.id, type: 'DESKTOP'};
      return sdk.pages.data.get('', {
        pageRef,
      });
    })
  ).then((pages: any) => {
    return pages.map((pageData) => pageData.tpaPageId);
  });

  const promises = [PageMap.CART, PageMap.PRODUCT, PageMap.THANKYOU, PageMap.CHECKOUT].map((mandatoryPage) => {
    if (!existsMandatoryPages.includes(mandatoryPage)) {
      // eslint-disable-next-line @typescript-eslint/no-floating-promises
      webBiLogger.report(
        exposureEventForTests({
          testName: 'missingPagesMigration',
          is_eligible: true,
          type: `${mandatoryPage}-${options.origin.type}`,
        })
      );
      return EPApi.addPage(mandatoryPage, WIX_NEW_STORES);
    } else {
      return Promise.resolve();
    }
  });

  await Promise.all<any>(promises);
}
