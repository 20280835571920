import {EditorSDK} from '@wix/editor-platform-sdk-types';
import {RouterPrefix} from '@wix/wixstores-client-core/dist/es/src/constants';
import {EcomPublicApi} from '../../editor-script/editorScript';
import {checkIfPageExists} from '../../common/pages';
import {PageMap} from '@wix/wixstores-client-core/dist/src/constants';
import {getCategoryPageSiteStatus} from './categories/categoriesinstallationUtils';
import {ContextParams} from '@wix/platform-editor-sdk';
import {markSiteWithCategoryPageStatus} from './categories/categoriesRolloutManagerUtils';
import {WIX_BOOKINGS, WIX_ECOM, WIX_NEW_STORES} from '@wix/app-definition-ids';
import {BLOCKS_PRODUCT_PAGE_APP_DEF_ID} from '../constants';
import {addBlocksProductPageToExistingSite} from './common';
import {StoresWidgetID} from '@wix/wixstores-client-core';

export async function fixCategoryPage(sdk: EditorSDK, options: ContextParams, ecomPublicApi: EcomPublicApi) {
  /* eslint-disable no-console */
  const isCategorySite = await getCategoryPageSiteStatus(options);
  console.log(`Is site marked as a Category Page site? ${isCategorySite ? 'Yes' : 'No'}`);
  if (!isCategorySite) {
    console.log('Marking site as a Category page site...');
    await markSiteWithCategoryPageStatus(options.essentials.httpClient, ecomPublicApi.getInstance(), true);
    console.log('Done marking');
  }

  const isCategoryPageExists = await checkIfPageExists(sdk, PageMap.CATEGORY);
  console.log(`Is Category Page installed? ${isCategoryPageExists ? 'Yes' : 'No'}`);
  let categoryPageRef;
  if (!isCategoryPageExists) {
    console.log('Adding Category page...');
    categoryPageRef = await ecomPublicApi.addPage(PageMap.CATEGORY, WIX_NEW_STORES, true);
    console.log('Done adding');
  } else {
    categoryPageRef = await sdk.tpa.getPageRefByTPAPageId('', {tpaPageId: PageMap.CATEGORY});
  }

  let routerRef = await ecomPublicApi.getRouterRefByPrefix(RouterPrefix.CATEGORY);
  console.log(`Has router? ${routerRef ? 'Yes' : 'No'}`);
  if (!routerRef) {
    console.log('Adding router...');
    routerRef = await ecomPublicApi.addRouter(RouterPrefix.CATEGORY);
    console.log('Done adding');
  }

  const router = await ecomPublicApi.getRouter(routerRef);
  console.log(`Is router connected? ${router.pages?.length ? 'Yes' : 'No'}`);
  if (!router || !router.pages?.length) {
    console.log('Connecting router...');
    await ecomPublicApi.connectPageToRouter({
      pageRef: categoryPageRef,
      routerRef,
      pageRoles: ['category'],
    });
    console.log('Done connecting');
  }

  console.log('Done!');
}

export async function fixBlocksProductPage(sdk: EditorSDK, fedopsLogger: Record<string, any>) {
  /* eslint-disable no-console */
  const hasOldProductPage = await checkIfPageExists(sdk, PageMap.PRODUCT);
  console.log(`Is Product Page installed? ${hasOldProductPage ? 'Yes' : 'No'}`);

  const isBlocksProductPageAppInstalled = await sdk.application.isApplicationInstalled('', {
    appDefinitionId: BLOCKS_PRODUCT_PAGE_APP_DEF_ID,
  });
  console.log(`Is Blocks Product Page App installed? ${isBlocksProductPageAppInstalled ? 'Yes' : 'No'}`);
  if (isBlocksProductPageAppInstalled) {
    console.log('Uninstalling Blocks Product Page App...');
    const blocksProductPageAppPublicApi: Record<string, any> = await sdk.application.getPublicAPI('', {
      appDefinitionId: BLOCKS_PRODUCT_PAGE_APP_DEF_ID,
    });

    await blocksProductPageAppPublicApi?.uninstallApp();
  }

  setTimeout(() => {
    console.log('Running migration...');
    void addBlocksProductPageToExistingSite(sdk, fedopsLogger).then(() => console.log('Done!'));
  }, 20000);
}

// eslint-disable-next-line sonarjs/cognitive-complexity
export async function analyzeStoresInstallation(sdk: EditorSDK, options: ContextParams, ecomPublicApi: EcomPublicApi) {
  /* eslint-disable no-console */
  const titleStyle = 'color: navy; font-family:sans-serif; font-size: 18px; text-decoration: underline';
  const subTitleStyle = 'color: orchid; font-family:sans-serif; font-size: 14px; text-decoration: underline';

  console.log(`%cApps`, titleStyle);
  console.log('');

  const isEcomPlatformAppInstalled = await sdk.application.isApplicationInstalled('', {appDefinitionId: WIX_ECOM});
  const isStoresAppInstalled = await sdk.application.isApplicationInstalled('', {appDefinitionId: WIX_NEW_STORES});
  const isBookingsAppInstalled = await sdk.application.isApplicationInstalled('', {appDefinitionId: WIX_BOOKINGS});
  console.log(`Is eCom Platform app installed? ${isEcomPlatformAppInstalled ? 'Yes' : 'No'}`);
  console.log(`Is Online Stores app installed? ${isStoresAppInstalled ? 'Yes' : 'No'}`);
  console.log(`Is Bookings app installed? ${isBookingsAppInstalled ? 'Yes' : 'No'}`);
  console.log('');

  console.log(`%cPages`, titleStyle);
  console.log('');

  console.log(`%cCategory Page`, subTitleStyle);
  console.log('');
  const isCategorySite = await getCategoryPageSiteStatus(options);
  console.log(`Is site marked as a Category Page site? ${isCategorySite ? 'Yes' : 'No'}`);
  const isCategoryPageExists = await checkIfPageExists(sdk, PageMap.CATEGORY);
  console.log(`Is Category Page installed? ${isCategoryPageExists ? 'Yes' : 'No'}`);
  const routerRef = await ecomPublicApi.getRouterRefByPrefix(RouterPrefix.CATEGORY);
  console.log(`Has router? ${routerRef ? 'Yes' : 'No'}`);
  const router = await ecomPublicApi.getRouter(routerRef);
  console.log(`Is router connected? ${router.pages?.length ? 'Yes' : 'No'}`);
  console.log('');

  console.log(`%cProduct Page`, subTitleStyle);
  console.log('');
  const hasOldProductPage = await checkIfPageExists(sdk, PageMap.PRODUCT);
  console.log(`Is Product Page installed? ${hasOldProductPage ? 'Yes' : 'No'}`);
  const isBlocksProductPageAppInstalled = await sdk.application.isApplicationInstalled('', {
    appDefinitionId: BLOCKS_PRODUCT_PAGE_APP_DEF_ID,
  });
  console.log(`Is Blocks Product Page App installed? ${isBlocksProductPageAppInstalled ? 'Yes' : 'No'}`);
  console.log('');

  console.log(`%cCart Page`, subTitleStyle);
  console.log('');
  const cartPageExists = await checkIfPageExists(sdk, PageMap.CART);
  console.log(`Is Cart Page installed? ${cartPageExists ? 'Yes' : 'No'}`);
  console.log('');

  console.log(`%cCheckout Page`, subTitleStyle);
  console.log('');
  const checkoutPageExists = await checkIfPageExists(sdk, PageMap.CHECKOUT);
  console.log(`Is Checkout Page installed? ${checkoutPageExists ? 'Yes' : 'No'}`);
  console.log('');

  console.log(`%cThank You Page`, subTitleStyle);
  console.log('');
  const thankYouPageExists = await checkIfPageExists(sdk, PageMap.THANKYOU);
  console.log(`Is Thank You Page installed? ${thankYouPageExists ? 'Yes' : 'No'}`);
  console.log('');

  if (isEcomPlatformAppInstalled) {
    const applicationId = (await sdk.tpa.app.getDataByAppDefId('', WIX_ECOM)).applicationId;
    const ecomComps = await sdk.document.tpa.app.getAllCompsByApplicationId('', applicationId);
    const countPerComp: {[id: string]: number} = {};
    for (const comp of ecomComps) {
      const entry = Object.entries(StoresWidgetID).find((e) => e[1] === comp.widgetId);
      const widgetName = entry?.[0].replace(/_/g, ' ') || comp.widgetId;
      countPerComp[widgetName] = countPerComp[widgetName] ? countPerComp[widgetName] + 1 : 1;
    }

    console.log(`%cAll Components`, titleStyle);
    console.log('');
    for (const compKey of Object.keys(countPerComp)) {
      console.log(`${compKey}: ${countPerComp[compKey]}`);
    }
    console.log('');
  }
}
