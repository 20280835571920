import {getEcomPages} from '../../common/pages';
import {PageMap} from '@wix/wixstores-client-core/dist/es/src/constants';
import {BLOCKS_PRODUCT_PAGE_APP_DEF_ID, FedopsInteractions} from '../constants';
import {EditorSDK} from '@wix/platform-editor-sdk';

async function getProductPageRef(sdk: EditorSDK) {
  const ecomPages = await getEcomPages(sdk);
  const productPage = ecomPages.find((page) => page.tpaPageId?.startsWith(PageMap.PRODUCT));
  return sdk.tpa.getPageRefByTPAPageId('', {tpaPageId: productPage?.tpaPageId});
}

export async function installBlocksProductPage(sdk: EditorSDK, fedopsLogger: Record<string, any>) {
  fedopsLogger?.interactionStarted?.(FedopsInteractions.BlocksProductPageAppInstallation);
  await sdk.document.tpa.add.application('', {
    appDefinitionId: BLOCKS_PRODUCT_PAGE_APP_DEF_ID,
  });
  fedopsLogger?.interactionEnded?.(FedopsInteractions.BlocksProductPageAppInstallation);
}

export async function addBlocksProductPageToExistingSite(sdk: EditorSDK, fedopsLogger: Record<string, any>) {
  const productPageRef = await getProductPageRef(sdk);
  await sdk.pages.remove('', {pageRef: productPageRef, shouldShowEditorRemovePanel: false});
  await installBlocksProductPage(sdk, fedopsLogger);
  return sdk.application.reloadManifest();
}
